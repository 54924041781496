import {http, param} from '../../utils/http'
import conf from '../../config'
import fileDownload from 'js-file-download'
export const API_ROOT = `${conf.API_URL}`

const orgServices = {
    findMeta(orgType = null) {
        return http.get(`${API_ROOT}/notisphere/organizations/meta${orgType == null ? '' : '?orgType=' + orgType}`).then(resp => {
            return resp
        })
    },
    findCreatorMeta() {
        return http.get(`${API_ROOT}/notisphere/organizations/recall-Orgmeta`).then(resp => {
            return resp
        })
    },
    findKPIMeta(organization_id) {
        return http.get(`${API_ROOT}/notisphere/organizations/kpi-meta/${organization_id == null ? 0 : organization_id}`).then(resp => {
            return resp
        })
    },
    //supplier
    findSuppliers(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'name', val: payload.name ? payload.name.trim() : '' },
            { query: 'add1', val: payload.add1 ? payload.add1.trim() : '' },
            { query: 'add2', val: payload.add2 ? payload.add2.trim() : '' },
            { query: 'city', val: payload.city ? payload.city.trim() : '' },
            { query: 'state', val: payload.state ? payload.state.trim() : '' },
            { query: 'sso', val: payload.sso ? payload.sso.trim() : '' },
            { query: 'zip', val: payload.zip ? payload.zip.trim() : '' },
            { query: 'stt', val: payload.stt ? payload.stt.trim() : '' },
        ]
        return http.get(`${API_ROOT}/notisphere/organizations/suppliers${param(arr)}`).then(resp => {
            return resp
        })
    },
    //supplier list
    async createSupplierName(payload) {
        return http.post(`${API_ROOT}/notisphere/organizations/create-suppliers`, payload).then(resp => {
            return resp
        })
    },
    GetSupplierNameList () {
        return http.get(`${API_ROOT}/notisphere/organizations/supplierNameList`).then(resp => {
            return resp
        })
    },
    async linkSupplierName(id,linkedSupplierId) {

        return http.put(`${API_ROOT}/notisphere/organizations/link-suppliers/${id}/${linkedSupplierId}`).then(resp => {
            return resp
        })
    },
    async findSuppliersList(payload,status) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'status', val: status },
        ]
        return http.get(`${API_ROOT}/notisphere/organizations/suppliers-list${param(arr)}`).then(resp => {
            return resp
        })
    },
    async updateSupplierList(id,status) {
        var arr = [
            { query: 'id', val: id },
            { query: 'status', val: status },
        ]
        return http.get(`${API_ROOT}/notisphere/organizations/update-suppliers-list${param(arr)}`).then(resp => {
            return resp
        })
    },
    getSupplier(organization_id) {
        return http.get(`${API_ROOT}/notisphere/organizations/suppliers/${organization_id}`).then(resp => {
            return resp
        })
    },
    updateSupplier(payload) {
        return http.put(`${API_ROOT}/notisphere/organizations/suppliers/${payload.id}`, payload).then(resp => {
            return resp
        })
    },
    createSupplier(payload) {
        return http.post(`${API_ROOT}/notisphere/organizations/suppliers`, payload).then(resp => {
            return resp
        })
    },
    deletePendingSupplier(organization_id) {
        return http.delete(`${API_ROOT}/notisphere/organizations/suppliers/${organization_id}`).then(resp => {
            return resp
        })
    },
    //provider
    findProviders(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'name', val: payload.name ? payload.name.trim() : '' },
            { query: 'add1', val: payload.add1 ? payload.add1.trim() : '' },
            { query: 'add2', val: payload.add2 ? payload.add2.trim() : '' },
            { query: 'city', val: payload.city ? payload.city.trim() : '' },
            { query: 'state', val: payload.state ? payload.state.trim() : '' },
            { query: 'zip', val: payload.zip ? payload.zip.trim() : '' },
            { query: 'stt', val: payload.stt ? payload.stt.trim() : '' },
            { query: 'sso', val: payload.sso ? payload.sso.trim() : '' },
            { query: 'notisphere_managed', val: payload.notisphere_managed ? payload.notisphere_managed.trim() : '' },
            { query: 'notisphere_id', val: payload.notisphere_id ? payload.notisphere_id.trim() : '' },
            { query: 'sites', val: payload.sites ? payload.sites.trim() : null}
        ]
        return http.get(`${API_ROOT}/notisphere/organizations/providers${param(arr)}`).then(resp => {
            return resp
        })
    },
    getProvider(organization_id) {
        return http.get(`${API_ROOT}/notisphere/organizations/providers/${organization_id}`).then(resp => {
            return resp
        })
    },
    updateProvider(payload) {
        return http.put(`${API_ROOT}/notisphere/organizations/providers/${payload.id}`, payload).then(resp => {
            return resp
        })
    },
    createProvider(payload) {
        return http.post(`${API_ROOT}/notisphere/organizations/providers`, payload).then(resp => {
            return resp
        })
    },
    deletePendingProvider(organization_id) {
        return http.delete(`${API_ROOT}/notisphere/organizations/providers/${organization_id}`).then(resp => {
            return resp
        })
    },
    getProviderOrgMetaData () {
        return http.get(`${API_ROOT}/notisphere/organizations/providerorgmeta`).then(resp => {
            return resp
        })
    },
    getProviderOrgDocData (payload) {
        return http.get(`${API_ROOT}/notisphere/organizations/providerdocdata/${payload.id}`).then(resp => {
            return resp
        })
    },
    downloadProviderAccountCSVRecall (payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
        ]
        return http.get(`${API_ROOT}/notisphere/organizations/downloadprovideraccountcsv${param(arr)}`,{ responseType: 'blob' }).then(resp => {
            var date = new Date()
            var DateTime = (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))  + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +'-'+ date.getFullYear())
            fileDownload(resp.data, 'Provider Account Report ' + DateTime +'.csv','text/csv')
        })
    },
    downloadProviderAccountXLSXRecall (payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
        ]
        return http.get(`${API_ROOT}/notisphere/organizations/downloadprovideraccountxlsx${param(arr)}`,{ responseType: 'blob' }).then(resp => {
            var date = new Date()
            var DateTime = (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))  + '-' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) +'-'+ date.getFullYear())
            fileDownload(resp.data, 'Provider Account Report ' + DateTime +'.xlsx','application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
        })
    },
}

export default orgServices
